<template>
  <div
    v-loading="loading"
    element-loading-background="rgba(122, 122, 122, 0.1)"
    element-loading-text="Please wait"
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    style="z-index: 1"
  >
    <div class="card card-lead-by-sources">
      <!-- v-model:modelPolicyStatus="filter.policy_status" -->
      <vc-report-filter
        v-model:modelStartDate="filter.start_date"
        v-model:modelEndDate="filter.end_date"
        v-model:modelYear="filter.year"
        show-year
        show-export-reset-first-row
        show-export-reset-second-row
        :show-export-second="false"
        :show-reset-second="false"
        @reset="reset"
        @export="exportData"
        :export-url="exportUrl"
        :showExportResetSecondRow="false"
        @handleYears="handleYears"
      />

      <div class="card-body card-custom-spacing-tblf-10 bg-white">
        <div class="pt-0 p-0" v-if="adminReports && adminReports.length > 0">
          <div id="displayResponseData">
            <!-- class="table table-hover bg-bai-table-header table-bai-striped table-row-bordered border gy-7 gs-7 mb-0" -->
            <el-table :data="adminReports">
              <el-table-column
                v-for="list in admin_report"
                :key="list.label"
                :fixed="list.isFixed"
                :width="list.width"
              >
                <template #header>
                  <span v-html="list.label" />
                </template>
                <template #default="scope">
                  <span v-if="list.isIndex">
                    {{ scope.$index + 1 }}
                  </span>
                  <span v-else-if="list.isFormat">
                    {{ common.setDateTimeOnly(scope.row[list.value]) }}
                  </span>
                  <span v-else-if="list.isCount">
                    <span
                      v-for="(n, index) in scope.row[list.value]"
                      :key="index"
                    >
                      {{ index + "(" + n + "), " }}
                    </span>
                  </span>

                  <span
                    v-else-if="scope.row && scope.row[list.value] != undefined && list.isNumber"
                    :class="[list.cn , {'text-bold': scope.$index == adminReports.length - 1}]"
                  >
                  {{ common.formatCurrency(scope.row[list.value]) }}
                </span>
                <span
                    v-else-if="scope.row && scope.row[list.value] != undefined && !isNaN(scope.row[list.value])"
                    :class="[list.cn , {'text-bold': scope.$index == adminReports.length - 1}]"
                  >
                  {{ common.formatNumber(scope.row[list.value]) }}
                </span>
                  <span
                    v-else
                    :class="[list.cn , {'text-bold': scope.$index == adminReports.length - 1}]"
                   
                    v-html="scope.row[list.value]"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-else>
          <div v-if="loading">
            <span>Loading Information Please Wait...</span>
          </div>
          <div v-else class="container-fluid p-5">
            <div class="row">
              <div class="col-12">
                <el-empty description="No Records Found" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Report from "@/core/services/Callable/Report";
import { admin_report } from "@/core/utils/reports";
import { store } from "@/store";
import moment from "moment";
import { common } from "@/store/stateless";

const filter = ref({
  start_date: "",
  end_date: "",
  year: "",
});

const exportUrl = ref('')

const loading = ref(false);

const adminReports = computed(() => {
  return store.getters.getAdminReport;
});

onMounted(async () => {
  setCurrentPageBreadcrumbs("Admin Report", [{ label: "Reports", value: "" }]);
  filter.value.start_date = moment().startOf("year").format("YYYY-MM-DD");
  filter.value.end_date = moment().endOf("year").format("YYYY-MM-DD");
  filter.value.year = moment().format("YYYY");
  await searchData(filter.value);
});

watch(
  [() => filter.value],
  async ([newValue], [oldValue]) => {
    exportUrl.value = '';

    

    searchData(newValue);
  },
  {
    deep: true,
  }
);

const handleYears = (e) => {
    const newDate = moment().year(e);
    const firstDate = new Date(newDate.year(), 0, 1);
    const lastDate = new Date(newDate.year(), 11, 31);
    filter.value.start_date = moment(firstDate).format("YYYY-MM-DD");
    filter.value.end_date = moment(lastDate).format("YYYY-MM-DD");
}


const searchData = async (value) => {
  loading.value = true;
  const data = await Report.getAdminReport(value);
  if (value.export) {
    exportUrl.value = data;
    // window.open(data);
  }
  loading.value = false;
};

const reset = async () => {
  console.log("reset");
  filter.value.start_date = moment().startOf("year").format("YYYY-MM-DD");
  filter.value.end_date = moment().endOf("year").format("YYYY-MM-DD");
  filter.value.year = moment().format("YYYY");
  // await searchData(filter.value);
};

const exportData = () => {
  const payload = {
    ...filter.value,
    export: true,
  };
  searchData(payload);
  console.log("export");
};
</script>
<style>
  .text-bold {
    font-weight: bold !important; 
    color: rgb(34, 34, 97) !important;
  }
</style>